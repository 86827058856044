const offerSeeMoreBtns = document.querySelectorAll(
	'.offer__box-item-see-more'
) as NodeListOf<HTMLButtonElement>

function showOfferItemInfo(this: HTMLElement): void {
	this.parentElement!.children[3].classList.toggle(
		'offer__box-item-text--active'
	)
}

offerSeeMoreBtns.forEach(btn =>
	btn.addEventListener('click', showOfferItemInfo)
)
